<template>
  <div>
    <div v-if="error_message" class="field_with_errors"><p class="error">{{error_message}}</p></div>
    <div class="datetime_wrap">
      <div class="select_wrap">
        <select name="start_date(1i)" id="form_start_date_1i" v-model="year">
          <option value=""></option>
          <option v-for="val in start_to_end" :value="val"><!-- ★ valueにinfoというオブジェクトを設定 -->
            {{ val }}
          </option>
        </select>
      </div>

      <div class="select_wrap">
        <select name="start_date(2i)" id="form_start_date_2i" v-model="month">
          <option value=""></option>
          <template v-if="language != 'en'">
            <option value="1">01</option>
            <option value="2">02</option>
            <option value="3">03</option>
            <option value="4">04</option>
            <option value="5">05</option>
            <option value="6">06</option>
            <option value="7">07</option>
            <option value="8">08</option>
            <option value="9">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </template>
          <template v-if="language == 'en'">
             <option value="1">Jan.</option>
             <option value="2">Feb.</option>
             <option value="3">Mar.</option>
             <option value="4">Apr.</option>
             <option value="5">May</option>
             <option value="6">Jun.</option>
             <option value="7">Jul.</option>
             <option value="8">Aug.</option>
             <option value="9">Sep.</option>
            <option value="10">Oct.</option>
            <option value="11">Nov.</option>
            <option value="12">Dec.</option>
          </template>
        </select>

      </div>

      <div class="select_wrap">
        <select name="start_date(3i)" id="form_start_date_3i" v-model="day">
          <option value=""></option>
          <option v-for="(key,val) in start_to_end_day" :value="key"><!-- ★ valueにinfoというオブジェクトを設定 -->
            {{ key }}
          </option>
        </select>

      </div>

    </div>

  </div>

</template>
<script>
import CommonModule from "../../module/common";

export default {
  init_prop_info_list: {},
  props: {
    prop_start_year: {type: Number, default: ""},
    prop_end_year: {type: Number, default: ""},
    prop_key: {type: String, default: ""},
    prop_default_year: {type: Number, default: ""},
    prop_default_month: {type: Number, default: ""},
    prop_default_day: {type: Number, default: ""},
  },
  data() {
    return {
      //初期値
      year: this.prop_default_year,
      month: this.prop_default_month,
      day: this.prop_default_day,
      start_to_end: [],
      start_to_end_day: [],
      error_message: "",
    }
  },
  watch: {
    year: function () {
      this.validate();
      index_simulator.simulate();
      this.$parent.check_submit();

    },
    month: function () {
      this.set_date();
      this.validate();
      index_simulator.simulate();
      this.$parent.check_submit();

    },
    day: function () {
      this.validate();
      index_simulator.simulate();
      this.$parent.check_submit();
    },

  },
  methods: {
    set_date() {
      if (this.year && this.month) {
        this.start_to_end_day = CommonModule.formSetDay(this.year, this.month);

      }

    },
    /*
    * 入力のチェック
    * */
    validate(){
      const simulator_start_date_year = parseInt(this.year);
      const simulator_start_date_month = parseInt(this.month);
      const simulator_start_date_day = parseInt(this.day);

      //エラーメッセージの初期化
      this.error_message = '';
      if(!simulator_start_date_year){
        return false;
      }

      if (!isDate(simulator_start_date_year + '/' + simulator_start_date_month + '/' + simulator_start_date_day)) {
        if( this.year != "" && this.month != "" && this.day != "" ){
          this.error_message = '保険始期日が存在しない日付です。';
        }
        return false;
      }

      /*
      * 下記はグローバル変数
      * selectable_start_date
      * selectable_end_date
      * selectable_restrect_start_date_start
      * selectable_restrect_start_date_start_message
      * selectable_restrect_start_date_end
      * selectable_restrect_start_date_end_message
      * */

      //始期日が違う
      if (selectable_start_date && selectable_start_date > getDate(simulator_start_date_year + '/' + simulator_start_date_month + '/' + simulator_start_date_day + ' 23:59:59')) {

        if (contractable_period_start_date_message) {
          this.error_message = contractable_period_start_date_message;
        } else{
          this.error_message = '保険始期日を' + getStrDate(selectable_start_date) + '以降でご設定ください。';
        }

        return false;
      }

      //始期日が違う 終了日計算
      if (selectable_end_date && selectable_end_date < getDate(simulator_start_date_year + '/' + simulator_start_date_month + '/' + simulator_start_date_day + ' 00:00:00')) {


        if (contractable_period_end_date_message) {
          this.error_message = contractable_period_end_date_message;
        } else{
          this.error_message = CommonModule.ml(['保険始期日を' + getStrDate(selectable_end_date) + '以前でご設定ください。','Please set the insurance start date before ' + getStrDate(selectable_end_date) + 'Please set before.']);
        }

        return false;
      }

      //当日以降のみ許可
      if (new Date() > getDate(simulator_start_date_year + '/' + simulator_start_date_month + '/' + simulator_start_date_day + ' 23:59:59')) {
        this.error_message = CommonModule.ml(['過去の保険始期日はご選択できません。','You cannot select a past insurance start date.']);
        return false;
      }

      if (selectable_restrect_start_date_start !== '' && CommonModule.check_date_gt(selectable_restrect_start_date_start, getDate(simulator_start_date_year + '/' + simulator_start_date_month + '/' + simulator_start_date_day + ' 23:59:59'))) {
        if (selectable_restrect_start_date_start_message) {
          this.error_message = selectable_restrect_start_date_start_message;
        } else {
          this.error_message = CommonModule.ml(['保険始期日を' + selectable_restrect_start_date_start + '日以降に設定してください。',
            'Set the insurance start date to ' + selectable_restrect_start_date_start + 'day or later']);
        }

        return false;
      }

      if (selectable_restrect_start_date_end !== '' && CommonModule.check_date_lt(selectable_restrect_start_date_end,
          getDate(simulator_start_date_year + '/' + simulator_start_date_month + '/' + simulator_start_date_day +
              ' 00:00:00'))) {
        if (selectable_restrect_start_date_end_message) {
          this.error_message = selectable_restrect_start_date_end_message;
        } else {
          this.error_message = CommonModule.ml(['保険始期日を' + selectable_restrect_start_date_end + '日以内に設定してください。',
            'Set the insurance start date to ' + selectable_restrect_start_date_end + 'days before.']);
        }

        return false;
      }

      // if (!isDate(simulator_end_date_year + '/' + simulator_end_date_month + '/' + simulator_end_date_day)) {
      //   this.error_message = '保険終期日が存在しない日付です。';
      //   return false;
      // }

      return true; //バリデーションOK
    }

  },
  mounted() {
    this.language = language;//グローバル変数から言語を取得
    this.set_date();

    var v = this;
    setTimeout(function(){

      let select_year = $('#form_start_date_1i').val();
      if (select_year){
        v.year = select_year;
      }

      let select_month = $('#form_start_date_2i').val();
      if (select_month){
        v.month = select_month;
      }


      let select_day = $('#form_start_date_3i').val();
      if (select_day){
        v.day = select_day;
      }
    },100);

  },
  created() {
    this.language = language;//グローバル変数から言語を取得
    this.start_to_end = CommonModule.range(this.prop_start_year, this.prop_end_year);
    this.set_date();

  }
}
</script>
